@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400;600;700;800;900&display=swap");
body {
  margin: 0;
  font-family: "Kanit", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box !important;
}

.slides {
  height: 100vh;
  transition: all 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

.slide {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-size: cover;
  position: relative;
}

/* .slide h2 {
  position: relative;
  opacity: 0;
  transition: all 600ms ease;
  transition-delay: 300ms;
} */

/* .slide.active h2 {
  opacity: 1;
} */

.buttons {
  display: flex;
  width: 100%;
  justify-content: center;
  position: fixed;
  bottom: 10px;
}

.buttons button {
  margin: 0 5px;
  cursor: pointer;
}

.elementToFadeInAndOut {
  -webkit-animation: fadeinout 2s linear;
  animation: fadeinout 2s linear;
}

@-webkit-keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes fadeinout {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
